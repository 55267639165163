const bitcoin = require('../images/currencies/bitcoin.png');
// const etherum = require('../images/currencies/Etherum.png');
// const ltc = require('../images/currencies/ltc.png');
// const luna = require('../images/currencies/luna.png');
const usdt = require('../images/currencies/usdt.png');
// const solana = require('../images/currencies/solana.png');
const busd = require('../images/currencies/busd.png');

const coins = [
    {
        name: 'Bitcoin',
        image: bitcoin.default,
        network: [
            'BTC'
        ],
        address: 'bc1q3eeycdtkswav8w3td9mppv4g3s00h08txr2wpl'
    },
    // {
    //     name: 'Ethereum',
    //     image: etherum.default,
    //     network: [
    //         'ERC (20)'
    //     ],
    //     address: ''
    // },
    {
        name: 'BUSD',
        image: busd.default,
        network: [
            'ERC (20)'
        ],
        address: '0x14aEcd8F4af3adEc22F873A8bb6821C5CffFf57b'
    },
    // {
    //     name: 'Luna',
    //     image: luna.default,
    //     network: [
    //         'LUN'
    //     ],
    //     address: ''
    // },
    {
        name: 'USDT',
        image: usdt.default,
        network: [
            'TRC (20)',
        ],
        address: 'TLusSnZtAcn6RtfLgd2zSZdr5fHY8ZcXb9'
    },
    // {
    //     name: 'Solana',
    //     image: solana.default,
    //     network: [
    //         'SOL'
    //     ],
    //     address: ''
    // },
];

export default coins